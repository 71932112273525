/*
$( document ).ready(function() {


    personFields = ['collector', 'owner'];

    //Ladda.bind('.ladda-button', { timeout: 2000 } );



    for(i = 0; i < personFields.length; i++) {
        var item = personFields[i];
        $("#"+item);

            $("#"+item).typeahead({
                source: function (query, callback) {
                    console.log(callback);
                    var data = [];
                    $.ajax({
                        url: site_url + '/'+item+'/list/' + query,
                        success: function (response) {
                            console.log(response);
                            data = response;
                        },
                        async: false
                    });
                    callback(data);
                }
            });
    }




    var ajaxFormSubmitButton = $('#ajaxFormSubmit').ladda();




    var $createSpecimenForm = $('#createSpecimenForm');

    $createSpecimenForm.bind('submit', function() {
        $(this).find(':disabled').removeAttr('disabled');
    });

    $createSpecimenForm.ajaxForm({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        beforeSend: function(){
            ajaxFormSubmitButton.ladda( 'start' );
        },
        uploadProgress: function(event, position, total, percentComplete){
            ajaxFormSubmitButton.ladda('setProgress', (percentComplete/100) );
        },
        success: function(response, d, f){
            ajaxFormSubmitButton.ladda( 'stop' );
            $('#createSpecimenFormHolder').html('<div class="alert alert-success" role="alert"><a href="#" class="alert-link">'+response+'</a></div>');

        },
        error: function(response, d, f, form){
            //console.log(g);
            markUpErrors(response, d, f, form);

            ajaxFormSubmitButton.ladda( 'stop' );
        }
    });

});

*/

$( document ).ready(function() {

});