
var markUpErrors = function(response, d, f, form){
    console.log(response.responseText);
    var data = JSON.parse(response.responseText);
    var key;

    form.find('label').each(function(){
        $(this).parent().removeClass('has-error').find('label span').remove();
    });

    for(key in data){

        label = form.find('label[for="'+key+'"]');

        label.parent().addClass('has-error');
        //label.find('span').remove();
        label.append($("<span>"+data[key]+"</span>"));
        //console.log(item.children('label').has('span'));

    }
};


$( document ).ready(function() {

    //console.log(laroute.route('locality.info', {string: 'troll'}));

    /*
    elt .tagsinput({
        itemValue: 'value',
        itemText: 'text',
        freeInput: false,
        typeahead: {
            source: [ { "value": 1, "text": "acanthite", "name": null } ],

            displayText: function(item) {
                if(item == undefined)
                    return;

                return item.name || item;
            }
        }
    });
    */

    $('#mineral').on('itemAdded', function(event, a, b) {
        console.log((event));
        //$('#mineral').trigger('change');
    }).tagsinput({
        confirmKeys: [59, 13],
        typeahead: {
            source: function(query){
                r =$.get(site_url+'/mineral/list/'+query);
                console.log(r);
                return r;
            },
            displayText: function(item) {
                if(item == undefined)
                    return;
                return item.name || item;
            }
        }
    });


    /*
    $('.bootstrap-tagsinput input').on('change', function (e) {
        console.log(e);
    });
    */
    var locality_data = [];
    $('#locality').on('beforeItemAdd', function(event) {
        event.item = 'troll';
        console.log(event);
        //return event;
    }).on('itemAdded', function(event) {
        console.log((event.item));

        $.ajax({
            url: site_url+'/locality/info/'+decodeEntities(event.item),
            //url: laroute.route('locality.info{item}', {item: 'item'}),
            success: function (response) {
                console.log(response);

                if(typeof response[0] !== 'undefined'){
                    $('#country option[value="'+response[0].country.id+'"]').attr("selected","selected");
                    $('#country').attr("readonly","readonly");
                }
                else{
                    $('#country').removeAttr("readonly");
                }


                //console.log(response[0].country.id);
            }
        });



    }).on('itemRemoved', function(event) {
        $('#country').removeAttr("readonly");
    }).tagsinput({
        maxTags:10,
        confirmKeys: [59, 13],
        typeahead: {
            source: function(query){
                //r =$.get(site_url+'/locality/list/'+query);

                var data = [];
                $.ajax({
                    url: site_url+'/locality/list/'+query,
                    success: function (response) {
                        locality_data = response;
                        data = response;
                    },
                    async: false
                });




                return data.getColumn('name');
            },

            displayText: function(item) {
                if(item == undefined)
                    return;
                return item.name || item;
            }
        }
    });

    $('.bootstrap-tagsinput').each(function(){
        $(this).addClass('form-control');
    })





    /* quill setup */

    var toolbarOptions = [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean']                                         // remove formatting button
    ];


    var options = {
      //debug: 'info',
      modules: {
        toolbar: toolbarOptions
      },
      placeholder: 'Compose an epic...',
      theme: 'snow'
    };

    var editor = new Quill('.wysiwyg', options);

    editor.on('text-change', function() {
      var html = editor.getText();
      console.log(editor.root.innerHTML);
      //console.log(document.getElementById('HTML_Container'));
      document.getElementById('HTML_Container').value = editor.root.innerHTML;
    });

    console.log(editor);
});
